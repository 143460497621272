import gql from "graphql-tag";
import { USER_FRAGMENT } from "./UserQuery";

export const TRAINING_FRAGMENT = gql`
    fragment generalTrainingFields on Training {
        id
        isActive
        isMemberOnly
        name
        description
        postEventDescription
        cloudflareVideoId
        startDate
        endDate
        locationType
        location {
            latitude
            longitude
        }
        venueName
        venueAddress
        bannerImage
        posterImage
        publicDocuments {
            id
            title
            fileName
            url
            bannerImage
            fileSize
            createdAt
            updatedAt
        }
        relatedArticles
        supportEmailAddress
        totalSeats
        enableGuestRegistration
        enableRegistration
        createdAt
        lastModifiedAt
        registrationLink
    }
`;

export const GET_TRAINING_EVENT = gql`
    query TrainingEvent($id: String!) {
        event: getTraining(id: $id) {
            ...generalTrainingFields
        }
    }
    ${TRAINING_FRAGMENT}
`;

export const GET_TRAINING_EVENTS = gql`
    query TrainingEvents(
        $limit: Int
        $skip: Int
        $order: String
        $filters: JSON
    ) {
        events: getTrainings(
            limit: $limit
            skip: $skip
            order: $order
            filters: $filters
        ) {
            ...generalTrainingFields
        }
    }
    ${TRAINING_FRAGMENT}
`;
// TS CODE CHANGE GET_TRAINING_REGISTERED_MEMBERS not used in convergence-app-server
// export const GET_TRAINING_REGISTERED_MEMBERS = gql`
//     query TrainingRegisteredMembers($id: String!) {
//         registeredMembers: getTrainingRegisteredMembers(id: $id) {
//             training_id
//             registered
//             user {
//                 ...generalUserFields
//             }
//         }
//     }
//     ${USER_FRAGMENT}
// `;

// TS CODE CHANGE GET_TRAINING_REGISTERED_GUESTS never used in convergence-app-server
// export const GET_TRAINING_REGISTERED_GUESTS = gql`
//     query TrainingRegisteredGuests($id: String!) {
//         registeredGuests: getTrainingRegisteredGuests(id: $id) {
//             training_id
//             name
//             email_address
//             access_code
//             institution_name
//             receive_notifications
//             is_active
//             gender
//         }
//     }
// `;

export const GET_GUEST_REGISTRATION = gql`
    query GetGuestRegistration(
        $id: String!
        $email_address: Email!
        $access_code: String!
    ) {
        registration: getGuestRegistration(
            id: $id
            email_address: $email_address
            access_code: $access_code
        ) {
            name
            training_id
            email_address
            institution_name
            gender
            access_code
            receive_notifications
            is_active
        }
    }
`;
