/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */
import React from "react";
import { WithApolloClient } from "react-apollo";
// Layout
import GeneralPage from "../components/layout/GeneralPage";
import { withApollo } from "../utils/WithApollo";

/**
 * Logout Page
 */
type Props = {
    title: string;
    statusCode: string;
};
class Error extends React.Component<WithApolloClient<Props>> {
    /**
     * Initial props
     * @param context
     * @returns {{statusCode: null}}
     */
    static getInitialProps({ res, err }) {
        const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
        let title;

        switch (statusCode) {
            case 404:
                title = "Page Not Found";
                break;
            default:
                title = statusCode + " Error";
        }

        return { statusCode, title };
    }

    /**
     * Generates the text for the error page based on the status code
     * @param statusCode
     * @returns {*}
     */
    renderError(statusCode) {
        switch (statusCode) {
            case 404:
                return (
                    <React.Fragment>
                        <h2 className="is-size-1">{statusCode}</h2>
                        <p>
                            The page you&#39;re looking for couldn&#39;t be
                            found.
                            <br />
                            Please contact your administrator for support or go
                            back to the home page.
                        </p>
                    </React.Fragment>
                );
            default:
                return (
                    <React.Fragment>
                        <h2 className="is-size-1">{statusCode}</h2>
                        <p>
                            An error {statusCode} has occurred. Please contact
                            an administrator.
                        </p>
                    </React.Fragment>
                );
        }
    }

    /**
     * Render our page
     * @returns {*}
     */
    render() {
        return (
            <GeneralPage title={this.props.title}>
                <div id="error-page-container">
                    {this.renderError(this.props.statusCode)}
                </div>
            </GeneralPage>
        );
    }
}
// TS TODO
// withApollo (src/utils/WithApollo.tsx) wrapper around a Page doesn't take <Props> argument
// this can be fixed in src/utils/WithApollo.tsx, but a pageComponent should not be used as a regular compontent
//  (and given props)
// @ts-ignore
export default withApollo(Error);
