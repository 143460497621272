import React, { ReactNode } from "react";
import theme from "../theme";

type Props = {
    id?; // passed on to <a>?
    children: ReactNode;
    href;
    active?: boolean;
    backgroundColor?;
};

type Ref = any;

let TabLink = React.forwardRef<Ref, Props>(
    ({ href, active, children = null, backgroundColor, ...props }, ref) => (
        <a href={href} className={active ? "active" : ""} ref={ref} {...props}>
            {children}
            <style jsx>{`
                 {
                    display: block;
                    padding: 0.5rem 1rem;

                    border: 3px solid transparent;
                    border-left: none;
                    border-right: none;
                    border-top: none;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    height: 100%;
                }

                .active {
                    color: ${theme.colour.gray600};
                    background-color: ${backgroundColor
                        ? backgroundColor
                        : theme.colour.white};
                    border-color: ${theme.colour.primary};
                }
            `}</style>
        </a>
    )
);

export default TabLink = React.memo(TabLink);
