import React from "react";
import theme from "../theme";

let Tabs = ({ children, margin }) => (
    <ul>
        {children}
        <style jsx>{`
             {
                display: flex;
                flex-wrap: nowrap;
                padding-left: 0;
                margin: ${margin ? margin : "0"};
                list-style: none;

                overflow-x: auto;
                overflow-y: hidden;

                margin-top: 0;
                box-sizing: border-box;
            }

            @media (min-width: ${theme.breakpoint.md}) {
                 {
                    flex-wrap: wrap;
                }
            }

            @media (min-width: ${theme.breakpoint.lg}) {
                 {
                    flex-wrap: wrap;
                }
            }

            @media (min-width: ${theme.breakpoint.xl}) {
                 {
                    flex-wrap: wrap;
                }
            }
        `}</style>
    </ul>
);

export default Tabs = React.memo(Tabs);
