import React from "react";

let Tab = ({ children, ...props }) => (
    <li {...props}>
        {children}
        <style jsx>{`
             {
                margin-bottom: -1px;
            }
        `}</style>
    </li>
);

export default Tab = React.memo(Tab);
